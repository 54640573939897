import { Link } from 'gatsby';
import React from 'react';
import Col from './layout/Col';
import Row from './layout/Row';
import useSiteMetadata from '../hooks/useSiteMetadata';

const Footer: React.FC = () => {
    const meta = useSiteMetadata();
    const { title, email, bookingUrl } = meta || {};
    const today = new Date();
    return (
        <footer className="w-full">
            <div className="bg-preparedBlue text-white">
                <div>
                    <Row
                        cols={{ sm: 1, md: 6, lg: 12 }}
                        className="container pt-16 lg:pt-20 lg:pb-4"
                    >
                        <Col
                            md={{ span: 6, start: 1 }}
                            lg={{ span: 10, start: 2 }}
                            className="text-center"
                        >
                            <h2 className="font-ace mb-12 xl:mb-14">
                                Lass uns Deine Brand voranbringen.
                            </h2>
                            <a
                                href={`mailto:${email || ''}`}
                                className="rounded-btn border group hover:text-black hover:bg-white mr-4 mb-4 md:mb-0"
                            >
                                {email}
                                <svg
                                    width="14"
                                    height="14"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="-rotate-90 stroke-white group-hover:stroke-black"
                                >
                                    <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                                        <path d="M13 3.77V13H3.77M13 13 1 1" />
                                    </g>
                                </svg>
                            </a>
                            <a
                                href={bookingUrl || ''}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="rounded-btn border group hover:text-black hover:bg-white"
                            >
                                Jetzt Beratung buchen
                                <svg
                                    width="14"
                                    height="14"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="-rotate-90 stroke-white group-hover:stroke-black"
                                >
                                    <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                                        <path d="M13 3.77V13H3.77M13 13 1 1" />
                                    </g>
                                </svg>
                            </a>
                        </Col>
                    </Row>
                </div>
                <div className="enable-animation mt-8 lg:mt-0">
                    <div className="relative flex overflow-hidden select-none gap-4">
                        <p className="marquee__content text-[#f5f5f5] font-serif leading-tight whitespace-nowrap text-[8rem] lg:text-[11rem] xxl:text-[20rem] pr-4">
                            Building better ecommerce for people
                        </p>
                        <p
                            aria-hidden="true"
                            className="marquee__content text-[#f5f5f5] font-serif leading-tight whitespace-nowrap text-[8rem] lg:text-[11rem] xxl:text-[20rem]"
                        >
                            Building better ecommerce for people
                        </p>
                    </div>
                </div>
                <div className="container flex py-8 justify-between">
                    <div>
                        <p className="font-base">
                            &copy;
                            {' '}
                            {today.getFullYear()}
                            {' '}
                            {title}
                        </p>
                    </div>
                    <ul className="font-base">
                        <li>
                            <Link
                                className="relative inline-block underline underline-offset-2"
                                to="/impressum/"
                            >
                                Impressum
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
