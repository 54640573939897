import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import useSiteMetadata from '../hooks/useSiteMetadata';
import PreparedLogo from '../images/logos/Prepared-Logo.svg';

interface NavigationProps {
    mobile?: boolean
    onCloseMenu?: () => void
}
const Navigation = ({ mobile, onCloseMenu }: NavigationProps): JSX.Element => {
    const meta = useSiteMetadata();
    const { bookingUrl } = meta || {};
    const links = [
        {
            label: 'Shopify',
            url: '/shopify-agentur-koeln/',
        },
        {
            label: 'Shopify Plus',
            url: '/shopify-plus-agentur/',
        },
        {
            label: 'Migration',
            url: '/shopify-migration/',
        },
        {
            label: 'Headless',
            url: '/headless-shopify/',
        },
    ];

    return (
        <div
            className={classNames(
                'bg-transparent',
            )}
            css={!mobile ? {
                '[aria-hidden="true"] &': {
                    display: 'none',
                },
            } : {}}
        >
            <div className="flex justify-between items-center">
                <Link
                    to="/"
                    className="hidden lg:block no-underline"
                    aria-label="Prepared"
                >
                    <PreparedLogo className="h-6 xl:h-10" />
                </Link>
                <nav
                    className={classNames(
                        'w-full lg:w-auto lg:flex-row lg:flex lg:bg-gray-100 lg:rounded-lg',
                        {
                            'mt-[80px]': mobile,
                        },
                    )}
                >
                    {links.map(({ label, url }) => (
                        <Link
                            className="nav-item text-gray-600 hover:text-blackLight transition duration-200"
                            to={url}
                            onClick={onCloseMenu}
                            css={{
                                '&[aria-current="page"]': {
                                    color: '#1e1d1c',
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '2px',
                                },
                            }}
                        >
                            {label}
                        </Link>
                    ))}
                </nav>
                <a
                    href={bookingUrl || ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hidden lg:block rounded-btn font-menu bg-black px-7 text-white"
                >
                    Termin buchen
                </a>
            </div>
        </div>
    );
};

export default Navigation;
